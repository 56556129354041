import { Controller } from '@hotwired/stimulus';

import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.mercadopago = new MercadoPago(this.publicKey);

    if (this.totalPrice == 0) {
      this.formTarget.onsubmit = (event) => this.onSubmit(event);
      return;
    }

    this.createBrick();
  }

  createBrick() {
    this.brick = this.mercadopago.cardForm({
      amount: this.amount,
      iframe: true,
      form: this.formStructure, callbacks: {
        onFormMounted: (error) => this.onFormMounted(error),
        onSubmit: (event) => this.onSubmit(event),
        onFetching: (resource) => this.onFetching(resource),
      },
    });
  }

  async onSubmit(event) {
    event.preventDefault();

    try {
      if (this.totalPrice == 0) {
        await post('/order_transactions/pay', {
          responseKind: 'turbo-stream',
          body: JSON.stringify({ payment: { order_details: this.orderDetails } }),
        });

        return;
      }

      const {
        paymentMethodId: payment_method_id,
        issuerId: issuer_id,
        cardholderEmail: email,
        amount,
        token,
        installments,
        identificationNumber,
        identificationType,
      } = this.brick.getCardFormData();

      await post('/order_transactions/pay', {
        responseKind: 'turbo-stream',
        body: JSON.stringify({
          payment: {
            token,
            issuer_id,
            payment_method_id,
            transaction_amount: +amount,
            installments: +installments,
            description: "Product Description",
            payer: {
              email,
              identification: {
                type: identificationType,
                number: identificationNumber,
              },
            },
            order_details: this.orderDetails,
          },
        }),
      });
    } catch (error) {
      console.error("Unexpected error", error);
    }
  }

  onFetching(resource) {
    // TODO: Improve UI with some progress var animation
    // const progressBar = document.querySelector(".progress-bar");
    // progressBar.removeAttribute("value");
    // return () => {
    //   progressBar.setAttribute("value", "0");
    // };
  }

  onFormMounted(error) {
    if (error) return console.warn("Form Mounted handling error: ", error);
  }

  get publicKey() {
    return document.querySelector('meta[name=mp_public_key]').content;
  }

  get totalPrice() {
    return parseInt(this.amount);
  }

  get amount() {
    return document.querySelector('meta[name=total_price]').content;
  }

  get formStructure() {
    return {
      id: "form-checkout",
      cardNumber: {
        id: "cardNumber",
        placeholder: "Card Number",
      },
      expirationDate: {
        id: "expirationDate",
        placeholder: "MM/YY",
      },
      securityCode: {
        id: "securityCode",
        placeholder: "Security Code",
      },
      cardholderName: {
        id: "transaction_holder_name",
        placeholder: "Cardholder",
      },
      issuer: {
        id: "transaction_issuer",
        placeholder: "Issuing bank",
      },
      installments: {
        id: "transaction_installments",
        placeholder: "Installments",
      },
      identificationType: {
        id: "transaction_identification_type",
        placeholder: "Document type",
      },
      identificationNumber: {
        id: "transaction_identification_number",
        placeholder: "Document number",
      },
      cardholderEmail: {
        id: "transaction_email",
        placeholder: "Email",
      },
    };
  }

  get orderDetails() {
    const menuVariants = new FormData(this.formTarget).getAll('transaction[orders_detail][orders][][menu_variant_id]');
    const comments = new FormData(this.formTarget).getAll('transaction[orders_detail][orders][][comment]');

    return menuVariants.map(function(menuVariant, index) {
      return { menu_variant_id: menuVariant, comment: comments[index] };
    });
  }
}
