import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["form"]
  static values = { url: String }

  async fetchForm() {
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  urlWithQueryString() {
    return `${this.urlValue}?${this.queryString()}`;
  }

  queryString() {
    const form = new FormData(this.formTarget);
    const params = new URLSearchParams();
    for (const [name, value] of form.entries()) {
      const input = document.querySelector(`input[name="${name}"]`);

      if (input === null || input.type !== 'file') {
        params.append(name, value);
      }
    }

    return params.toString();
  }
}
