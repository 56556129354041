import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['preloader'];

  connect() {
    if (this.preloaderTarget != null) {
      window.addEventListener('turbo:load', () => {
        document.querySelector('body').classList.add('loaded-success');
      });
    }
  }
}
