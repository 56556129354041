import { Application } from '@hotwired/stimulus';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

import {
  Alert,
  Autosave,
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover,
} from 'tailwindcss-stimulus-components';
import Carousel from 'stimulus-carousel';
import NestedForm from 'stimulus-rails-nested-form';

application.register('alert', Alert);
application.register('autosave', Autosave);
application.register('carousel', Carousel);
application.register('dropdown', Dropdown);
application.register('modal', Modal);
application.register('nested-form', NestedForm);
application.register('popover', Popover);
application.register('slideover', Slideover);
application.register('tabs', Tabs);
application.register('tabs2', Tabs);
application.register('toggle', Toggle);

export { application };
