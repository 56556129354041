import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['sidebar', 'content', 'navbar'];
  static values = { open: Boolean }

  initialize() {
    this.openValue = true;

    this.showSidebarDependingOnSize();

    window.addEventListener('resize', () => {
      this.showSidebarDependingOnSize();
    });
  }

  toggle(event) {
    event.preventDefault();

    this.openValue = !this.openValue;
  }

  openValueChanged() {
    if (this.openValue) {
      this.showSidebar();
    } else {
      this.hideSidebar();
    }
  }

  showSidebarDependingOnSize() {
    if (window.innerWidth < 739) {
      this.openValue = false;
    } else {
      this.openValue = true;
    }
  }

  showSidebar() {
    this.sidebarTarget.classList.remove('-ml-64', 'md:ml-0');

    // -right-64 and -mr-64 make it posible for the entire content to move without
    // shrinking in mobile screens. In desktop the content will adjust to the window width.
    this.navbarTarget.classList.add('left-64', '-right-64', 'md:left-64', 'md:right-0');
    this.contentTarget.classList.add('ml-64', '-mr-64', 'md:ml-64', 'md:mr-0');
  }

  hideSidebar() {
    this.sidebarTarget.classList.add('-ml-64');
    this.navbarTarget.classList.remove('left-64', '-right-64', 'md:left-64', 'md:right-0');
    this.contentTarget.classList.remove('ml-64', '-mr-64', 'md:ml-64', 'md:mr-0');
  }
}
