import { Controller } from '@hotwired/stimulus';
import { DataTable } from 'simple-datatables';

export default class extends Controller {
  static targets = ['table'];

  connect() {
    this.tableTargets.forEach((element) => {
      new DataTable(element, {
        classes: this.tableClasses(),
      });
    });
  }

  tableClasses() {
    return  {
      active: 'active',
      bottom: 'dataTable-bottom',
      container: 'dataTable-container',
      cursor: 'dataTable-cursor',
      dropdown: 'dataTable-dropdown',
      ellipsis: 'ellipsis',
      empty: 'dataTable-empty',
      headercontainer: 'dataTable-headercontainer',
      info: 'dataTable-info',
      input: 'dataTable-input',
      loading: 'dataTable-loading',
      pagination: 'dataTable-pagination',
      paginationList: 'dataTable-pagination-list',
      search: 'dataTable-search',
      selector: 'dataTable-selector',
      sorter: 'dataTable-sorter',
      table: 'dataTable-table',
      top: 'dataTable-top',
      wrapper: 'dataTable-wrapper'
    }
  }
}
