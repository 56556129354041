import { Controller } from '@hotwired/stimulus';

import flatpickr from 'flatpickr';

export default class extends Controller {
  static targets = ['datepickers', 'startDatepickers', 'endDatepickers'];

  connect() {
    this.datepickersTargets.forEach((target, index) => {
      this.datepickersTargetConnected(target, index);
    });
  }

  datepickersTargetConnected(target) {
    flatpickr(target, {
      allowInput: true,
      dateFormat: 'd/m/Y',
      defaultDate: target.value,
    });
  }
}
