import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'topShadow', 'bottomShadow'];

  connect() {
    this.checkScrollable();
  }

  checkScrollable() {
    const container = this.containerTarget;

    if (container.scrollTop === 0) {
      this.topShadowTarget.classList.add('hidden');
    } else {
      this.topShadowTarget.classList.remove('hidden');
    }

    if (container.scrollHeight === container.clientHeight + container.scrollTop) {
      this.bottomShadowTarget.classList.add('hidden');
    } else {
      this.bottomShadowTarget.classList.remove('hidden');
    }
  }
}
