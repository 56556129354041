import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['navMenu'];

  openClassList = ['duration-150', 'ease-out', 'opacity-100', 'scale-100'];
  closeClassList = ['duration-100', 'ease-in', 'opacity-0', 'scale-95'];

  toggleMenu() {
    this.openClassList.forEach((cssClass) => this.navMenuTarget.classList.toggle(cssClass));
    this.closeClassList.forEach((cssClass) => this.navMenuTarget.classList.toggle(cssClass));
  }
}
