import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  onPress() {
    this.buttonTargets.forEach((button) => {
      button.classList.add('loading');
      button.disabled = true;
    });
  }

  reset() {
    this.buttonTargets.forEach((button) => {
      button.classList.remove('loading');
      button.disabled = false;
    });
  }
}
